<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content main_content">
      <div class="item_box">
        <div class="top">
          <div class="title">用药清单</div>
        </div>
        <div class="line_box" style="margin-top: 17px;">
          <div>
            <p class="name">头孢拉丁缓释片0.5mg*20</p>
            <p class="info">用法每日二次 一次0.5mg/一片</p>
          </div>
          <div class="num">6盒</div>
        </div>
        <div class="line_box">
          <div>
            <p class="name">头孢拉丁缓释片0.5mg*20</p>
            <p class="info">用法每日二次 一次0.5mg/一片</p>
          </div>
          <div class="num">2盒</div>
        </div>
        <div class="bottom_box">
          <div>医师： <label>杨俊涛</label></div>
          <div>药师： <label>杨立群</label></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loading: false,
      id: this.$route.query.id,
    }
  },
  computed: {
  },
  components: {
  },
  created () {
  },
  methods: {

  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_content {
  background: #F7F7F7;
  padding: 0px 16px 20px;
}
.item_box {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
  opacity: 1;
  border-radius: 10px;
  margin-top: 15px;
  padding: 17px 10px;
  & .top {
    display: flex;
    align-items: center;
    & .title {
      flex: 1;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #292929;
    }
    & .type {
      line-height: 22px;
      color: #0088FF;
    }
    & .time {
      line-height: 22px;
      color: #999;
    }
  }
  & .p {
    line-height: 20px;
    color: #292929;
    margin-top: 5px;
    & label {
      color: #0088FF;
    }
  }
}
.line_box {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  & .name {
    color: #292929;
    line-height: 20px;
  }
  & .info {
    color: #919191;
    line-height: 20px;
    font-size: 12px;
  }
  & .num {
    font-weight: 500;
    line-height: 24px;
    color: #292929;
  }
  & .result {
    line-height: 20px;
    color: #FF0000;
    &.c2 {
      color: #00AF28;
    }
  }
}
.bottom_box {
  border-top: 1px solid rgba(193, 193, 193, 0.3);
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  padding: 13px 0 3px;
  color: #999;
  & > div {
    margin-right: 15px;
    & label {
      color: #292929;
    }
  }
}
</style>
